<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Avatars -->
      <b-col
        lg="12"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <!-- Brand logo-->
        <b-link style="position: absolute; top:0; right: 0" to="dashboard">
          <img src="@/assets/images/logo/logo.png" alt="" class="logo">
        </b-link> 
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="text-light text-center mb-5">
            <p style="font-size: 1.5em">Pick your character...</p>
          </b-card-title>
          <div class="w-100 text-center">
            <img 
              v-if="!form.selected_avatar"
              src="@/assets/images/profile/avatars/bg_color.png" 
              class="m-auto cursor-pointer"
              alt="" 
            >
            <img 
              v-else
              :src="form.selected_avatar" 
              class="m-auto cursor-pointer"
              alt="" 
            >
            <!-- <input
              ref="refInputEl"
              type="file"
              class="d-none"
              :value="form.selected_avatar"
            > -->
          </div>
          <div class="w-100 text-center pt-3" v-if="form.selected_avatar">
            <button 
              class="btn btn-primary mx-auto px-5 rounded-pill" 
              @click="continueRegistration()"
            >
              Continue
            </button>
          </div>
          <hr class="mt-5">
          <!-- form -->
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="register"
          >
            <validation-observer
              class="w-100"
              ref="registerForm"
              #default="{invalid}"
            >
              <b-row class="w-100 d-flex">
                <b-col sm="6" md="3" lg="1" xlg="1" v-for="index in 115" :key="index" class="text-center">
                  <img 
                    :src="require(`@/assets/images/profile/avatars/Number=${index}.png`)" 
                    alt="avatar" 
                    class="cursor-pointer"
                    @click="selectAvatar(require(`@/assets/images/profile/avatars/Number=${index}.png`))"
                  >
                </b-col>
              </b-row>
            </validation-observer>
          </b-form>

        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BFormFile, BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText, BFormDatepicker,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
    BFormDatepicker,
    BFormFile, 
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      avatars:[
        {id: 1 , path:'@/assets/images/profile/avatars/Number'}
      ],

      form:{
        selected_avatar: null,
      }
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$router.push({name:'registration_form'})
        }
      })
    },
    selectAvatar(avatar_path){
      this.form.selected_avatar = avatar_path
      this.$store.state.avatar = this.form.selected_avatar
    },
    continueRegistration(){
      this.$router.push({name:'registration_form'})
    }
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
*{
  font-family: "Inter", sans-serif !important;
}
.logo{
  width: 200px;
}
@media (max-width: 992px) {
  .logo{
    width: 100px;
  }
  .auth-inner{
    display: flex !important;
    justify-content: center !important;
  }
}

.auth-wrapper{
  background: url('../../../assets/images/banner/registrationFormBG.png') no-repeat;
  background-size: cover;
}
.auth-bg{
  background-color: #5a5a5a32 !important;
  color: #fff !important
}
.btn-secondary {
    border-color: #82868b !important;
    background-color: transparent !important;
    border-top: unset !important;
}
.inner-addon{ 
    position: relative; 
}
.inner-addon:hover { 
    position: relative; 
}
.btn-secondary:hover { 
  box-shadow: unset !important;
}
.inner-addon .feather-mail,.feather-lock {
  position: absolute;
  top: 16px;
  left: 10px;
  // pointer-events: none;
}
// .form-control{
//   background-color:rgba(0,0,0,0) !important;
//   border:0px solid #888 !important;
//   height: 50px;
//   border-bottom: 1px solid #9CA3AF !important;
//   border-radius: 0px;
//   padding: 0px 0px 0px 20px;
//   color: #fff !important
// }
.btn-primary{
  background-color: #E84185 !important;
  border-color: #E84185 !important;
  box-shadow: unset !important;
}
</style>
